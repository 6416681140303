'use client'
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
interface AppContextType {
    isMobileView: boolean;
    isAppView: boolean;
    setIsMobileView: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAppView: React.Dispatch<React.SetStateAction<boolean>>;
}
const AppContext = createContext<AppContextType | undefined>(undefined);
export const AppContextProvider = ({ children }: { children: ReactNode }) => {
    const [isMobileView, setIsMobileView] = useState(true);
    const [isAppView, setIsAppView] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent || '';
        const isAppView = /AppWebView/i.test(userAgent);
        const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(userAgent);
        const isSmallScreen = window.innerWidth < 1024;
        // Set states based on conditions
        setIsAppView(isAppView);
        // A device is considered "mobile" only if it's a mobile device and has a small screen
        setIsMobileView(!isAppView && isMobileDevice && isSmallScreen);
        // Listen for window resize to handle tablet landscape/portrait changes dynamically
        const handleResize = () => {
            if (!isAppView && isMobileDevice) {
                setIsMobileView(window.innerWidth < 1024);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <AppContext.Provider value={
            {
                isMobileView,
                isAppView,
                setIsAppView,
                setIsMobileView
            }}>
            {children}
        </AppContext.Provider>
    );
};
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within a FestivalProvider');
    }
    return context;
};
