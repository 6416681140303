'use client';
import React, { useEffect, useState, useCallback, ReactElement, useRef } from 'react';
import Image from 'next/image';
import { noto_sans, ubuntu } from '@/globalConstants';
import { event } from '@/services/gtag';
import Link from 'next/dist/client/link';
import { usePathname } from 'next/dist/client/components/navigation';
import { useAppContext } from '@/contexts/AppContext';
const Header = () => {
    const { isMobileView, isAppView } = useAppContext();
    const [NavBar, setNavBar] = useState<React.ComponentType<any> | null>(null);
    const BASE_CLASS = 'header';
    const pathname = usePathname();
    const handleHamburger = useCallback(() => {
        if (!isAppView) {
            const hams = document.querySelectorAll(".hamburger");
            const navBar = document.querySelector(".header__floating-nav-bar");
            const headerTag = document.querySelector(".header");
            const mainTag = document.querySelector(".panchanga-app-container");
            const datePicker = document.querySelector(".MuiDateCalendar-root");
            datePicker?.classList.toggle('pointer-events-none');
            hams[1].classList.toggle("hidden");
            hams[0].classList.toggle("translate-y-[3px]");
            hams[2].classList.toggle("-translate-y-[3px]");
            hams[0].classList.toggle("rotate-[40deg]");
            hams[2].classList.toggle("-rotate-[40deg]");
            navBar?.classList.toggle("top-[-100%]");
            navBar?.classList.toggle("top-[4.5rem]");
            navBar?.classList.toggle("fixed");
            navBar?.classList.toggle("absolute");
            if (pathname === '/apps/' && mainTag?.scrollTop! < 1) {
                headerTag?.classList.toggle('bg-[#05574E]');
            }
        }
    }, [ isAppView, pathname]);

    useEffect(() => {
        if (!isMobileView && !isAppView && NavBar === null) {
            import('./NavBar').then((module) => setNavBar(() => module.default));
        }
    }, [isMobileView, isAppView, NavBar]);

    useEffect(() => {
        const navBar = document.querySelector('.header__floating-nav-bar');
        if (navBar && !navBar.classList.contains('top-[-100%]')) {
            handleHamburger();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bgColor = pathname === '/apps/' ? '' : 'bg-[#05574E]';
    const fixHeader = pathname === '/apps/' ? 'fixed' : '';
    const displayType = (pathname.split("/")[1] === 'learn-more' || pathname.split("/")[1] === 'learn-more-mobile') ? 'hidden' : 'grid';
    return (
        !isAppView ?
            <header className={`header pt-5 z-[100] w-[100%] ${displayType} items-center ${bgColor} ${fixHeader}`} onClick={() => {
                document.querySelectorAll('.base-Popup-root').forEach((karyaDrop) => {
                    if (!karyaDrop.classList.contains('invisible')) {
                        karyaDrop.classList.add('invisible');
                    }
                });
            }}>
                <div className={`${BASE_CLASS}__nav-bar pb-[1rem] flex justify-center`}>
                    <div className="flex justify-between items-center w-[95%] md:w-[90%] 2xl:w-[70%]">
                        <div className={`${BASE_CLASS}__logo`}>
                            <Link href="/">
                                <div className="flex justify-center items-center">
                                    <Image
                                        className="logo-flower-img w-[1.125rem] h-[2.75rem] ml-[3%]"
                                        src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/dharmayana-flower.svg`}
                                        alt="Dharmāyana Flower"
                                        height={1}
                                        width={1}
                                        priority
                                    />
                                    <Image
                                        priority
                                        className="logo-title-img w-[10.18rem] h-[1.5rem] mt-[3%]"
                                        src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/dharmayana.svg`}
                                        alt="Dharmāyana Logo"
                                        height={1}
                                        width={1}
                                    />
                                </div>
                            </Link>
                        </div>
                        <div className={`${BASE_CLASS}__hamburger-conatiner cursor-pointer lg:hidden`}>
                            <ul className="grid gap-[0.3rem]" onClick={handleHamburger}>
                                {['', '', ''].map((_, index) => (
                                    <li key={index} className="hamburger w-[1.5rem] h-[2px] border-solid border-[1px] border-white rounded-[1rem] bg-white duration-500"></li>
                                ))}
                            </ul>
                        </div>
                        {NavBar && <NavBar BASE_CLASS={BASE_CLASS} pathname={pathname} />}
                        <div className={`${BASE_CLASS}__floating-nav-bar fixed top-[-100%] left-0 w-[100%] ${noto_sans.className} duration-500 bg-[#05574e] pt-5 z-[996] lg:hidden text-[1rem] xl:hidden`}>
                            <ul className={`${BASE_CLASS}__nav-links grid gap-10 font-bold tracking-[0.15em] text-white`}>
                                <li className={`${BASE_CLASS}__nav-links__link cursor-pointer hover:text-[#ebe793] hover:text-shadow transition delay-150 duration-200 ease-in-out px-5`} onClick={() => location.replace("/")}>Home</li>
                                <li className={`${BASE_CLASS}__nav-links__link cursor-pointer hover:text-[#ebe793] hover:text-shadow transition delay-150 duration-200 ease-in-out px-5`} onClick={() => { handleHamburger(); location.replace("/articles"); }}>Articles</li>
                                <li className={`${BASE_CLASS}__nav-links__link cursor-pointer hover:text-[#ebe793] hover:text-shadow transition delay-150 duration-200 ease-in-out px-5`} onClick={() => { handleHamburger(); location.replace("/learn-more-web"); }}>Learn More</li>
                                <li className={`${BASE_CLASS}__nav-links__link cursor-pointer hover:text-[#ebe793] hover:text-shadow transition delay-150 duration-200 ease-in-out px-5`} onClick={() => { handleHamburger(); location.replace(pathname + "#contact"); }}>Contact Us</li>
                                <li className={`${BASE_CLASS}__nav-links__link cursor-pointer border-solid border-t-[0.1rem] py-[1.5rem] px-[1rem]`}>
                                    <button className={`${BASE_CLASS}__nav-links__contact-us w-[100%] rounded-[100px] bg-[#FFFFFF] px-4 py-2 text-[#1D4749] hover:text-[#fe9b35] border-solid border-[#FFFFFF] border-2 hover:border-[#fe9b35] outline-offset-2 outline-4 transition delay-150 duration-200 ease-in-out`} onClick={() => { handleHamburger(); location.replace("/apps"); }}>
                                        App
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {pathname !== '/apps/' && (
                    <div className={`${BASE_CLASS}__nudge-bar py-[12px] flex justify-center bg-[#B7C988]`}>
                        <div className={`${BASE_CLASS}__nudge-bar__container flex justify-between w-[95%] md:w-[90%] 2xl:w-[70%] items-center`}>
                            <div className={`${BASE_CLASS}__nudge-bar__text__container md:basis-none`}>
                                <p className={`${BASE_CLASS}__nudge-bar__text__heading ${ubuntu.className} font-[500] text-[18px] text-[#2C5652]`}>DOWNLOAD OUR APP</p>
                                <p className={`${BASE_CLASS}__nudge-bar__text__subtext ${noto_sans.className} font-[500] text-[14px] text-[#2C5652]`}>Daily Hindu App</p>
                            </div>
                            <div className={`${BASE_CLASS}__nudge-bar__download-buttons__container grid gap-1 justify-end md:flex`}>
                                {['Android', 'IOS'].map((platform) => (
                                    <a key={platform} href={process.env[`NEXT_PUBLIC_KOCHAVA_LINK_${platform.toUpperCase()}`]} target="_blank">
                                        <Image
                                            priority={true}
                                            fetchPriority="high"
                                            loading="eager"
                                            id={`play-store-header`}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/${platform === 'Android' ? 'Play Store' : 'iOS'} Badge.webp`}
                                            height={50}
                                            width={161}
                                            alt={`Download the app for ${platform}`}
                                            className="hover:scale-110 h-[50px] transition delay-150 duration-300 ease-in-out"
                                            onClick={() => {
                                                event({
                                                    action: `Download_${platform}_App_Clicked`,
                                                    category: 'home page',
                                                    label: 'my event',
                                                    value: 'hello'
                                                });
                                            }}
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </header> : <></>
    );
};
export default React.memo(Header);
